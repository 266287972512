import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react'
import Loader from '../../../components/loader';
import { VersionStatus } from '../../../utils/enum';
import { PricingVersionsOrderWiseCountEntity } from '../../../interface/pricing-version';
import { APIFetchPricingVersionOrderWiseCount } from '../../../service/api-service';
import { AxiosResponse } from 'axios';
import { APIResponseEntity } from '../../../interface/api-responce';
import { toast } from 'react-toastify';
import { toastOptObj } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';

function PricingVersionsOrderWiseCounts() {

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const [pricingVersionOrderCountData, setPricingVersionOrderCountData] = useState<PricingVersionsOrderWiseCountEntity[] | []>([])

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            await fetchPricingVersionOrderCount();
        };
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchPricingVersionOrderCount = async (): Promise<void> => {
        setIsLoading(true);
        const resp: AxiosResponse<APIResponseEntity> = await APIFetchPricingVersionOrderWiseCount();
        setIsLoading(false);
        setPricingVersionOrderCountData([]);

        if (resp) {
            if (resp && resp.data && resp.status === 200) {
                const axiosResp: APIResponseEntity = resp.data;

                if (axiosResp && axiosResp.data && axiosResp.status && axiosResp.statusCode === 200) {
                    const pricingVersionOrderCount: PricingVersionsOrderWiseCountEntity[] = axiosResp.data.data;

                    setPricingVersionOrderCountData(pricingVersionOrderCount);
                }
            }
            else {
                toast.error(resp.data.message, { ...toastOptObj });
            }
        }
    };

    const statusBodyTemplate = (rowData: any) => {
        const badgeClass = rowData.status === VersionStatus.DRAFT
            ? 'bg-primary' : rowData.status === VersionStatus.LIVE
                ? 'bg-success' : '';

        const status = rowData.status === VersionStatus.DRAFT
            ? 'Draft' : rowData.status === VersionStatus.LIVE
                ? 'Live' : '';

        return (
            <>
                <div className='d-flex justify-content-between'>
                    <span className={`badge ${badgeClass}`}>
                        {status}
                    </span>
                </div>
            </>
        );
    };

    return (
        <>
            <Loader isLoading={isLoading} />
            <div className="content-wrapper">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-rounded">
                                    <div className="card-body">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-12">
                                                    <h4 className="card-title d-flex">
                                                        Order Wise Pricing Version Count
                                                    </h4>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12 text-lg-end">
                                                    <button
                                                        type="button"
                                                        className="btn btn-cancel text-dark ms-2"
                                                        onClick={() => {
                                                            navigate("/settings/pricing-version");
                                                        }}
                                                    >
                                                        Back to Versions
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col mt-3">
                                            <div className='col-12'>
                                                <DataTable value={pricingVersionOrderCountData}>
                                                    <Column field="version" header="Version" />
                                                    <Column field="createdAt" header="Release Date" />
                                                    <Column header="Status" body={statusBodyTemplate} field='status' />
                                                    <Column header="Pipeline" field="pipelineCount" />
                                                    <Column header="Draft" field="draftCount" />
                                                    <Column header="Win" field="winCount" />
                                                    <Column header="Commercial Hold" field="commercialHoldCount" />
                                                    <Column header="Handover" field="handoverCount" />
                                                    <Column header="Expired" field="expiredCount" />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PricingVersionsOrderWiseCounts
