import React, { useState } from 'react'
import { ChangePasswordEntity, ChangePasswordReqEntity } from '../../interface';
import { IsStringNullEmptyOrUndefined } from '../../utils/null-check';
import { IsFormValid, checkValidInputOnKeyDown, removeSpaceOnFirstCharacter } from '../../service/shared.service';
import { NOT_ALLOW_SPACE, PASSWORD_PATTERN } from '../../utils/pattern';
import Loader from '../../components/loader';
import axios, { AxiosResponse } from 'axios';
import { APIResponseEntity } from '../../interface/api-responce';
import { ApiChangePassword } from '../../service/api-service';
import { toast } from 'react-toastify';
import { toastOptObj } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';

function ChangePassword() {

    const navigate = useNavigate();

    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

    const [formValues, setFormValues] = useState<ChangePasswordEntity>({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    const [formErrors, setFormErrors] = useState<ChangePasswordEntity>({
        currentPassword: 'Please enter current password',
        newPassword: 'Please enter new password',
        confirmPassword: 'Please enter confirm password'
    });

    const handleChange = (fieldName: string, value: any) => {
        const updatedValues = { ...formValues, [fieldName]: value };
        const updatedErrors = { ...formErrors };

        switch (fieldName) {
            case 'currentPassword':
                updatedErrors.currentPassword = IsStringNullEmptyOrUndefined(value) ? 'Please enter password' : '';
                break;

            case 'newPassword':
                const isValidNewPassword = PASSWORD_PATTERN.test(value);
                updatedErrors.newPassword = IsStringNullEmptyOrUndefined(value) ? 'Please enter password' :
                    !isValidNewPassword ? 'Enter password from 8 to 32 characters with at least 1 lowercase, 1 uppercase, 1 numeric and 1 special character' : '';
                break;

            case 'confirmPassword':
                const isValidConfirmPassword = PASSWORD_PATTERN.test(value);
                updatedErrors.confirmPassword = IsStringNullEmptyOrUndefined(value) ? 'Please enter password' :
                    !isValidConfirmPassword ? 'Enter password from 8 to 32 characters with at least 1 lowercase, 1 uppercase, 1 numeric and 1 special character' : '';
                break;

            default:
                break;
        }

        if (fieldName === 'newPassword' || fieldName === 'currentPassword') {
            if (updatedValues.newPassword === updatedValues.currentPassword) {
                updatedErrors.newPassword = 'The new password must be different from the current password';
            } else {
                updatedErrors.newPassword = '';
            }
        }

        if (fieldName === 'newPassword' || fieldName === 'confirmPassword') {
            if (updatedValues.newPassword !== updatedValues.confirmPassword) {
                updatedErrors.confirmPassword = 'New and confirm password should be same';
            } else {
                updatedErrors.confirmPassword = '';
            }
        }

        setFormValues(updatedValues);
        setFormErrors(updatedErrors);
    }

    const handleClearButtonClick = () => {
        setFormValues({
            ...formValues, currentPassword: '', newPassword: '', confirmPassword: ''
        })
        setFormErrors({
            ...formErrors, currentPassword: 'Please enter current password', newPassword: 'Please enter new password', confirmPassword: 'Please enter confirm password'
        })
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsFormSubmitted(true);
        const isValid = IsFormValid(formErrors);

        if (isValid) {
            setIsLoading(true);
            const reqObj: ChangePasswordReqEntity = {
                oldPassword: formValues.currentPassword,
                newPassword: formValues.newPassword
            }

            const resp: AxiosResponse<APIResponseEntity> = await ApiChangePassword(reqObj)

            setIsLoading(false);
            if (resp && resp.data && resp.status === 200) {
                if (resp.data.status && resp.data.statusCode === 200) {
                    toast.success(resp.data.message, { ...toastOptObj });
                    setTimeout(() => { navigate('/'); }, 100);
                } else {
                    toast.error(resp.data.message, { ...toastOptObj });
                }
            }

            if (axios.isAxiosError(resp) && resp.response) {
                console.log(resp.response);
            }
        }
    }

    return (

        <>
            <Loader isLoading={isLoading} /><div className="content-wrapper">
                <div className='col-12'>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 mb-md-0 mb-3">
                            <div className="card card-rounded">
                                <div className="card-body">
                                    <h4 className="card-title"> Change Password </h4>
                                    <form className="pt-3" autoComplete='off' onSubmit={(e) => handleSubmit(e)}>
                                        <div className='row'>
                                            <div className="form-group">
                                                <label>Current Password
                                                    <sup className='mandatory'>*</sup>
                                                </label>
                                                <div className="input-group pwd-input-grp">
                                                    <input type={showCurrentPassword ? 'text' : 'password'} className="form-control form-control-lg"
                                                        maxLength={30} name='currentPassword' value={formValues.currentPassword}
                                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                                        onKeyDown={(event: any) => {
                                                            checkValidInputOnKeyDown(event, NOT_ALLOW_SPACE);
                                                            removeSpaceOnFirstCharacter(event);
                                                        }} />
                                                    <span className="input-group-text cursor-pointer">
                                                        <img src={showCurrentPassword ? "assets/images/eye-slash.svg"
                                                            : "assets/images/eye.svg"} onClick={() => setShowCurrentPassword(!showCurrentPassword)} alt="eye" />
                                                    </span>
                                                </div>
                                                {isFormSubmitted &&
                                                    <small className="text-danger font-weight-500">
                                                        {formErrors.currentPassword}
                                                    </small>
                                                }
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="form-group">
                                                <label>New Password
                                                    <sup className='mandatory'>*</sup>
                                                </label>
                                                <div className="input-group pwd-input-grp">
                                                    <input type={showNewPassword ? 'text' : 'password'} className="form-control form-control-lg"
                                                        maxLength={30} name='newPassword' value={formValues.newPassword}
                                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                                        onKeyDown={(event: any) => {
                                                            checkValidInputOnKeyDown(event, NOT_ALLOW_SPACE);
                                                            removeSpaceOnFirstCharacter(event);
                                                        }} />
                                                    <span className="input-group-text cursor-pointer">
                                                        <img src={showNewPassword ? "assets/images/eye-slash.svg"
                                                            : "assets/images/eye.svg"} onClick={() => setShowNewPassword(!showNewPassword)} alt="eye" />
                                                    </span>
                                                </div>
                                                {isFormSubmitted &&
                                                    <small className="text-danger font-weight-500">
                                                        {formErrors.newPassword}
                                                    </small>
                                                }
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="form-group">
                                                <label>Confirm Password
                                                    <sup className='mandatory'>*</sup>
                                                </label>
                                                <div className="input-group pwd-input-grp">
                                                    <input type={showConfirmPassword ? 'text' : 'password'} className="form-control form-control-lg"
                                                        maxLength={30} name='confirmPassword' value={formValues.confirmPassword}
                                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                                        onKeyDown={(event: any) => {
                                                            checkValidInputOnKeyDown(event, NOT_ALLOW_SPACE);
                                                            removeSpaceOnFirstCharacter(event);
                                                        }} />
                                                    <span className="input-group-text cursor-pointer">
                                                        <img src={showConfirmPassword ? "assets/images/eye-slash.svg"
                                                            : "assets/images/eye.svg"} onClick={() => setShowConfirmPassword(!showConfirmPassword)} alt="eye" />
                                                    </span>
                                                </div>
                                                {isFormSubmitted &&
                                                    <small className="text-danger font-weight-500">
                                                        {formErrors.confirmPassword}
                                                    </small>
                                                }
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <button type="submit" className="btn btn-block btn-primary btn-md font-weight-medium m-0"

                                            >
                                                Save Password
                                            </button>
                                            {(!IsStringNullEmptyOrUndefined(formValues.currentPassword) || !IsStringNullEmptyOrUndefined(formValues.newPassword) || !IsStringNullEmptyOrUndefined(formValues.confirmPassword)) && <button
                                                type="button"
                                                className="btn btn-cancel text-dark ms-4 btn-md font-weight-medium"
                                                onClick={handleClearButtonClick}
                                            >Clear</button>}
                                        </div>
                                    </form>
                                    {/* : <div className="alert alert-danger" role="alert">
        You don't have permission to download PDF
    </div>
    } */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default ChangePassword