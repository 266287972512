import React, { lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import NonAuthLayout from '../layout/non-auth-layout';
import AuthAppLayout from '../layout/auth-layout';
import App from '../App';
import { allFeaturedModules } from '../utils/constants';
import ViewLedgerDetails from '../pages/view-ledger-details/view-ledger-details';
import ChangePassword from '../pages/change-password/change-password';
import UnderMaintenance from '../pages/sign-in/under-maintenance';
import CustomerFeedbackFormPDF from '../pages/procore-projects-PDF-links/customer-feedback-form-pdf';
import PricingVersionsOrderWiseCounts from '../pages/settings/pricing-version/pricing-versions-order-wise-counts';


const SignInPage = lazy(() => import('../pages/sign-in/sign-in'));
const ObtainProcoreAuthCodePage = lazy(() => import('../pages/obtain-procore-authorization-code/obtain-procore-authorization-code'));

const DashboardPage = lazy(() => import('../pages/dashboard/dashboard'));

const PercentageUpdatePage = lazy(() => import('../pages/settings/percentage-update/percentage-update-view'));
const AddPercentageUpdatePage = lazy(() => import('../pages/settings/percentage-update/add-percentage-update'));

const PaymentresponsePage = lazy(() => import('../pages/payment-response/payment-response'));

//role
const RoleManagementPage = lazy(() => import('../pages/role-management/role-management'));
const CreateUpdateRoleManagementPage = lazy(() => import('../pages/role-management/create-update-role-management'));

// settings
const SettingsPage = lazy(() => import('../pages/settings/settings'));
const GeneralSettingPage = lazy(() => import('../pages/settings/general-settings/general-settings'));
const UsersListPage = lazy(() => import('../pages/settings/users/users-list'));
const CreateUpdateUserPage = lazy(() => import('../pages/settings/users/create-update-user'));

const PricingVersionPage = lazy(() => import('../pages/settings/pricing-version/pricing-version'));
const ViewChangeLogsPage = lazy(() => import('../pages/settings/view-change-logs/view-change-logs'));
const CreateNewVersionPage = lazy(() => import('../pages/settings/create-new-version/create-new-version'));

const SystemTypePage = lazy(() => import('../pages/settings/system-type/system-types'));
const SubSystemPage = lazy(() => import('../pages/settings/sub-system/sub-system'));
// const BrandsPage = lazy(() => import('../pages/settings/brands/brands'));
const SystemPage = lazy(() => import('../pages/settings/systems/systems'));
const CreateUpdateSystemPage = lazy(() => import('../pages/settings/systems/create-update-systems'));
const ColorPage = lazy(() => import('../pages/settings/frame-colors/frame-colors'));
const WaltzVariantsPage = lazy(() => import('../pages/settings/waltz-variants/waltz-variants'));
const CreateUpdateWaltzVariantsPage = lazy(() => import('../pages/settings/waltz-variants/create-update-waltz-variants'));
const ModelsPage = lazy(() => import('../pages/settings/models/models'));
const CreateUpdateModelPage = lazy(() => import('../pages/settings/models/create-update-models'));
// const FrameFinishesPage = lazy(() => import('../pages/settings/frame-finishes/frame-finishes'));
const WaltzCategoriesPage = lazy(() => import('../pages/settings/waltz-categories/waltz-categories'));
const WaltzSubCategoriesPage = lazy(() => import('../pages/settings/waltz-sub-categories/waltz-sub-categories'));
const WaltzFinishesPage = lazy(() => import('../pages/settings/waltz-finishes/waltz-finishes'));
const CreateUpdateWaltzFinishesPage = lazy(() => import('../pages/settings/waltz-finishes/create-update-waltz-finishes'));
const HandlesPage = lazy(() => import('../pages/settings/handles/handles'));
// const HandlesVariantsPage = lazy(() => import('../pages/settings/handles-variants/handles-variants'));
// const CreateUpdateHandlesVariantsPage = lazy(() => import('../pages/settings/handles-variants/create-update-handles-variants'));
const DesignsPage = lazy(() => import('../pages/settings/designs/designs'));
const CreateUpdateDesignsPage = lazy(() => import('../pages/settings/designs/create-update-designs'));
const GridsPage = lazy(() => import('../pages/settings/grids/grids'));
const CreateUpdateGridsPage = lazy(() => import('../pages/settings/grids/create-update-grids'));


// const DesignPatternsPage = lazy(() => import('../pages/settings/design-patterns/design-patterns'));
// const CompanyPage = lazy(() => import('../pages/settings/company/company'));
const KitchenTypesPage = lazy(() => import('../pages/settings/kitchen-types/kitchen-types'));
const CreateUpdateKitchenTypePage = lazy(() => import('../pages/settings/kitchen-types/create-update-kitchen-types'));
const HingesPage = lazy(() => import('../pages/settings/hinges/hinges'));
const KitchenHandlesPage = lazy(() => import('../pages/settings/kitchen-handles/kitchen-handles'));
const GlassOnlyFinishesPage = lazy(() => import('../pages/settings/glass-only-finishes/glass-only-finishes'));
const CreateUpdateGlassOnlyFinishesPage = lazy(() => import('../pages/settings/glass-only-finishes/create-update-glass-only-finishes'));
const KitchenHandlePositionPage = lazy(() => import('../pages/settings/kitchen-handle-position/kitchen-handle-position'));
// const DrawingsPage = lazy(() => import('../pages/settings/drawings/drawings'));
// const CreateUpdateDrawingsPage = lazy(() => import('../pages/settings/drawings/create-update-drawings'));
const LockPage = lazy(() => import('../pages/settings/lock/lock'));

const StatesCitiesMasterPage = lazy(() => import('../pages/settings/states-cities-master/states-cities-master'));
const OfficesPage = lazy(() => import('../pages/settings/offices/offices'));
const CreateUpdateOfficesPage = lazy(() => import('../pages/settings/offices/create-update-offices'));
const ViewRegionWiseOfficePage = lazy(() => import('../pages/settings/offices/view-region-wise-office'));

const PaymentPage = lazy(() => import('../pages/transactions/payment/payment'));
const PaymentHistoryPage = lazy(() => import('../pages/transactions/payment-history/payment-history'));
const OrderDuePage = lazy(() => import('../pages/order-due/order-due'));
const SettlementsPage = lazy(() => import('../pages/transactions/settlements/settlements'));
const CreateUpdateArchitectDetailsPage = lazy(() => import('../pages/directory/create-update-architect-firm'));
const DirectoryMasterPage = lazy(() => import('../pages/directory/directory-master/directory-master'));
const ArchitectDetailsPage = lazy(() => import('../pages/directory/architect-firm'));
const DraftTransactionsPage = lazy(() => import('../pages/transactions/draft-transactions/draft-transactions'));
const CreateUpdateUserDirectoryPage = lazy(() => import('../pages/directory/directory-master/create-update-directory-user'));
const CreateUpdateCompanyDirectoryPage = lazy(() => import('../pages/directory/directory-master/create-update-directory-company'));
const RefundPaymentPage = lazy(() => import('../pages/transactions/refund-payment/refund-payment'));
const RefundPaymentHistoryPage = lazy(() => import('../pages/transactions/refund-payment-history/refund-payment-history'));

// prime-react-data-table 
// const PrimeReactDataTablePage = lazy(() => import('../pages/prime-react-data-tables/prime-react-data-tables'));

// download Module
const DownloadsPage = lazy(() => import('../pages/downloads/downloads'));

//anylatics module 
const AnalyticsPage = lazy(() => import('../pages/analytics/analytics'));

// wall order 
const OrdersPage = lazy(() => import('../pages/wall-orders/orders/orders'));
const ManageOrderPage = lazy(() => import('../pages/wall-orders/manage-order/manage-order'));
const ManageSolutionsPage = lazy(() => import('../pages/wall-orders/manage-solutions/manage-solutions'));
const ManageCreateSolutionsPage = lazy(() => import('../pages/wall-orders/manage-solutions/manage-solution-details'));
const ManageEditSolutionsPage = lazy(() => import('../pages/wall-orders/manage-solutions/edit-solution-details'));
const LedgerFormPage = lazy(() => import('../pages/wall-orders/ledger-form/ledger-form'));
const CustomerBillingForm = lazy(() => import('../pages/wall-orders/customer-billing-form/customer-billing-form'));
const ViewWaltzOrderPage = lazy(() => import('../pages/wall-orders/view-waltz-order/view-waltz-order'));
const CreateUpdatePanelOnly = lazy(() => import('../pages/settings/panel-only/create-update-panel-only'));

// glass order 
// const GlassOrdersPage = lazy(() => import('../pages/glass-orders/glass-orders'));
// const ManageGlassOrdersPage = lazy(() => import('../pages/glass-orders/manage-glass-order/manage-glass-order'));
// const ManageGlassOrderSolutionsPage = lazy(() => import('../pages/glass-orders/manage-glass-solutions/manage-glass-solutions'));
const ManageCreateGlassSolutionsPage = lazy(() => import('../pages/wall-orders/manage-solutions/manage-glass-solution-details'));
const ManageEditGlassSolutionsPage = lazy(() => import('../pages/wall-orders/manage-solutions/edit-glass-order-solution-details'));
// const ViewGlassOrderPage = lazy(() => import('../pages/glass-orders/view-glass-order/view-glass-order'));

// Page not found
const PageNotFoundPage = lazy(() => import('../pages/page-not-found/page-not-found'));
const ForbiddenPage = lazy(() => import('../pages/page-not-found/forbidden'));

const ExecutionOrderPage = lazy(() => import('../pages/execution-order/execution-order'));
const EditExecutionOrderPage = lazy(() => import('../pages/execution-order/manage-execution-order/edit-execution-order'))
const EditDraftsman = lazy(() => import('../pages/execution-order/manage-execution-order/edit-draftsman'))

function AppRoutes() {
    return (
        <>
            <App />

            <BrowserRouter>
                <Routes>

                    <Route path='' element={<NonAuthLayout page={<SignInPage />} />} />

                    <Route path='/view-ledger-details/:id' element={<NonAuthLayout page={<ViewLedgerDetails modelOrderId={''} />} />} />

                    <Route path='/projects-PDF-links/:proCoreId/:PDFCategory' element={<NonAuthLayout page={<CustomerFeedbackFormPDF />} />} />

                    <Route path='/obtain-procore-authorization-code' element={<NonAuthLayout page={<ObtainProcoreAuthCodePage />} />} />
                    <Route
                        path='execution-order'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.executionOrder}
                            page={<ExecutionOrderPage />}
                        />}
                    />
                    <Route
                        path='execution-order/:orderId'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.executionOrder}
                            page={<EditExecutionOrderPage />}
                        />}
                    />
                    <Route
                        path='draftsman-order/:orderId'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.executionOrder}
                            page={<EditDraftsman />}
                        />}
                    />
                    <Route
                        path='dashboard'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.dashboard}
                            page={<DashboardPage />}
                        />}
                    />

                    <Route
                        path='settings/percentage-update'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.percentageUpdate}
                            page={<PercentageUpdatePage />}
                        />}
                    />


                    <Route
                        path='settings/percentage-update/add-percentage-update'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.percentageUpdate}
                            page={<AddPercentageUpdatePage />}
                        />}
                    />

                    <Route
                        path='role-management'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.roleManagement}
                            page={<RoleManagementPage />}
                        />}
                    />

                    <Route
                        path='role-management/create-update-role'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.dashboard}
                            page={<CreateUpdateRoleManagementPage />}
                        />}
                    />




                    {/* settings */}
                    <Route
                        path='settings' element={<AuthAppLayout
                            featuredPage={allFeaturedModules.settings}
                            page={<SettingsPage />}
                        />}
                    />

                    <Route
                        path="settings/general-settings"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.generalSettings}
                            page={<GeneralSettingPage />}
                        />}
                    />

                    <Route
                        path="settings/users" element={<AuthAppLayout
                            featuredPage={allFeaturedModules.users}
                            page={<UsersListPage />}
                        />}
                    />

                    <Route
                        path="settings/users/create-update-user"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.users}
                            page={<CreateUpdateUserPage />}
                        />}
                    />

                    <Route
                        path="settings/users/create-update-user/:id"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.users}
                            page={<CreateUpdateUserPage />}
                        />}
                    />

                    <Route
                        path="settings/pricing-version"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.pricingVersion}
                            page={<PricingVersionPage />}
                        />}
                    />

                    <Route
                        path="settings/pricing-version-order-wise-counts"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.PricingVersionsOrderWiseCounts}
                            page={<PricingVersionsOrderWiseCounts />}
                        />}
                    />

                    <Route
                        path="settings/view-change-logs/:id/"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.pricingVersion}
                            page={<ViewChangeLogsPage />}
                        />}
                    />



                    <Route
                        path="settings/create-new-version"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.pricingVersion}
                            page={<CreateNewVersionPage />}
                        />}
                    />


                    <Route
                        path="settings/system-types"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.systemTypes}
                            page={<SystemTypePage />}
                        />}
                    />

                    <Route
                        path="settings/sub-system"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.subSystems}
                            page={<SubSystemPage />}
                        />}
                    />

                    {/* <Route
                        path="settings/brands"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.brand}
                            page={<BrandsPage />}
                        />}
                    /> */}

                    <Route
                        path="settings/systems"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.systems}
                            page={<SystemPage />}
                        />}
                    />

                    <Route
                        path="settings/systems/create-update-system"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.systems}
                            page={<CreateUpdateSystemPage />}
                        />}
                    />

                    <Route
                        path="settings/frame-colors"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.frameColors}
                            page={<ColorPage />}
                        />}
                    />

                    <Route
                        path="settings/waltz-variants"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.waltzVariants}
                            page={<WaltzVariantsPage />}
                        />}
                    />

                    <Route
                        path="settings/waltz-variants/create-update-waltz-variants"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.waltzVariants}
                            page={<CreateUpdateWaltzVariantsPage />}
                        />}
                    />

                    <Route
                        path="settings/models"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.models}
                            page={<ModelsPage />}
                        />}
                    />

                    <Route
                        path="settings/models/create-update-models"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.models}
                            page={<CreateUpdateModelPage />}
                        />}
                    />

                    {/* <Route
                        path="settings/frame-finishes"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.glassFinishes}
                            page={<FrameFinishesPage />}
                        />}
                    /> */}

                    <Route
                        path="settings/waltz-categories"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.waltzCategories}
                            page={<WaltzCategoriesPage />}
                        />}
                    />

                    <Route
                        path="settings/waltz-sub-categories"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.waltzSubCategories}
                            page={<WaltzSubCategoriesPage />}
                        />}
                    />

                    <Route
                        path="settings/waltz-finishes"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.waltzFinishes}
                            page={<WaltzFinishesPage />}
                        />}
                    />

                    <Route
                        path="settings/waltz-finishes/create-update-waltz-finishes"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.waltzFinishes}
                            page={<CreateUpdateWaltzFinishesPage />}
                        />}
                    />

                    {/* <Route
                        path="settings/glass-variants"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.glassVariants}
                            page={<GlassVariantsPage />}
                        />}
                    />

                    <Route
                        path="settings/glass-finishes"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.glassFinishes}
                            page={<GlassFinishesPage />}
                        />}
                    /> */}

                    <Route
                        path="settings/handles"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.handles}
                            page={<HandlesPage />}
                        />}
                    />
                    <Route
                        path="settings/create-update-panel-only"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.panelOnly}
                            page={<CreateUpdatePanelOnly />}
                        />}
                    />



                    {/* <Route
                        path="settings/handles-variants"
                        element={<AuthAppLayout
                            page={<HandlesVariantsPage />}
                        />}
                    /> */}

                    {/* <Route
                        path="settings/handles-variants/create-update-handles-variants"
                        element={<AuthAppLayout
                            page={<CreateUpdateHandlesVariantsPage />}
                        />}
                    /> */}

                    <Route
                        path="settings/designs"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.designs}
                            page={<DesignsPage />}
                        />}
                    />

                    <Route
                        path="settings/designs/create-update-designs"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.designs}
                            page={<CreateUpdateDesignsPage />}
                        />}
                    />

                    <Route
                        path="settings/grids"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.grids}
                            page={<GridsPage />}
                        />}
                    />

                    <Route
                        path="settings/grids/create-update-grids"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.grids}
                            page={<CreateUpdateGridsPage />}
                        />}
                    />

                    {/* <Route
                        path="settings/design-patterns"
                        element={<AuthAppLayout
                            page={<DesignPatternsPage />}
                        />}
                    /> */}

                    {/* <Route
                        path="settings/company"
                        element={<AuthAppLayout
                            page={<CompanyPage />}
                        />}
                    /> */}

                    <Route
                        path="settings/kitchen-types"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.kitchenType}
                            page={<KitchenTypesPage />}
                        />}
                    />

                    <Route
                        path="settings/kitchen-types/create-update-kitchen-types"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.kitchenType}
                            page={<CreateUpdateKitchenTypePage />}
                        />}
                    />

                    <Route
                        path="settings/hinges"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.hinges}
                            page={<HingesPage />}
                        />}
                    />

                    <Route
                        path="settings/kitchen-handles"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.hinges}
                            page={<KitchenHandlesPage />}
                        />}
                    />

                    <Route
                        path="settings/glass-only-finishes"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.glassOnlyFinish}
                            page={<GlassOnlyFinishesPage />}
                        />}
                    />

                    <Route
                        path="settings/glass-only-finishes/create-update-glass-only-finishes"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.glassOnlyFinish}
                            page={<CreateUpdateGlassOnlyFinishesPage />}
                        />}
                    />

                    {/* <Route
                        path="settings/glass-sub-categories"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.glassSubCategories}
                            page={<GlassSubCategoriesPage />}
                        />}
                    /> */}

                    <Route
                        path="settings/kitchen-handle-position"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.kitchenHandlePositions}
                            page={<KitchenHandlePositionPage />}
                        />}
                    />

                    {/* <Route
                        path="settings/glass-categories"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.glassCategories}
                            page={<GlassCategoriesPage />}
                        />}
                    /> */}

                    {/* <Route
                        path="settings/drawings"
                        element={<AuthAppLayout
                            page={<DrawingsPage />}
                        />}
                    /> */}

                    {/* <Route
                        path="settings/drawings/create-update-drawings"
                        element={<AuthAppLayout
                            page={<CreateUpdateDrawingsPage />}
                        />}
                    /> */}

                    <Route
                        path="settings/lock"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.locks}
                            page={<LockPage />}
                        />}
                    />


                    {/* anylatics-routes */}
                    <Route
                        path='/analytics'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.analytics}
                            page={<AnalyticsPage />}
                        />}
                    />


                    {/* download */}
                    <Route
                        path='/downloads'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.downloads}
                            page={<DownloadsPage />}
                        />}
                    />

                    {/* <Route path='/prime-react-data-tables' element={<AuthAppLayout page={<PrimeReactDataTablePage />} />} /> */}

                    {/* wall-orders */}
                    <Route
                        path='wall-orders'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.wallOrders}
                            page={<OrdersPage />}
                        />}
                    />
                    <Route
                        path='manage-wall-order'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.wallOrders}
                            page={<ManageOrderPage />}
                        />}
                    />
                    <Route
                        path='manage-wall-order-solution/:orderId'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.wallOrders}
                            page={<ManageSolutionsPage />}
                        />}
                    />
                    <Route
                        path='create-wall-order-solution/:orderId'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.wallOrders}
                            page={<ManageCreateSolutionsPage />}
                        />}
                    />
                    <Route
                        path='edit-wall-order-solution/:orderId/:solutionId'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.wallOrders}
                            page={<ManageEditSolutionsPage />}
                        />}
                    />

                    <Route
                        path='ledger-form/:orderId/'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.wallOrders}
                            page={<LedgerFormPage />}
                        />}
                    />

                    <Route
                        path='/customer-billing-form'
                        element={<NonAuthLayout
                            page={<CustomerBillingForm />}
                        />}
                    />

                    <Route
                        path='view-waltz-order/:orderId/'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.wallOrders}
                            page={<ViewWaltzOrderPage />}
                        />}
                    />

                    {/* glass-orders
                    <Route
                        path='glass-orders'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.glassOrders}
                            page={<GlassOrdersPage />}
                        />}
                    />
                    <Route
                        path='manage-glass-orders'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.glassOrders}
                            page={<ManageGlassOrdersPage />}
                        />}
                    />
                    <Route
                        path='manage-glass-order-solution/:orderId'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.glassOrders}
                            page={<ManageGlassOrderSolutionsPage />}
                        />}
                    />

                    <Route
                        path='view-glass-order/:orderId/'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.glassOrders}
                            page={<ViewGlassOrderPage />}
                        />}
                    /> */}

                    <Route
                        path='create-glass-order-solution/:orderId'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.wallOrders}
                            page={<ManageCreateGlassSolutionsPage />}
                        />}
                    />

                    <Route
                        path='edit-glass-order-solution/:orderId/:solutionId'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.wallOrders}
                            page={<ManageEditGlassSolutionsPage />}
                        />}
                    />

                    <Route path='/forbidden' element={<NonAuthLayout page={<ForbiddenPage />} />} />

                    <Route path='*' element={<PageNotFoundPage />} />

                    <Route
                        path='user/change-password'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.changePassword}
                            page={<ChangePassword />}
                        />}
                    />

                    <Route
                        path='settings/states-cities-masters'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.statesCitiesMaster}
                            page={<StatesCitiesMasterPage />}
                        />}
                    />

                    <Route
                        path='settings/offices'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.offices}
                            page={<OfficesPage />}
                        />}
                    />

                    <Route
                        path='settings/offices/create-update'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.offices}
                            page={<CreateUpdateOfficesPage />}
                        />}
                    />

                    <Route
                        path='settings/offices/view-region-wise-office'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.offices}
                            page={<ViewRegionWiseOfficePage />}
                        />}
                    />

                    <Route
                        path='/paymentresponse'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.transactions}
                            page={<PaymentresponsePage />}
                        />}
                    />

                    <Route path='/under-maintenance' element={<NonAuthLayout page={<UnderMaintenance />} />} />

                    <Route
                        path='transactions/payment'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.transactions}
                            page={<PaymentPage />}
                        />}
                    />

                    <Route
                        path='transactions/payment-history'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.paymentHistory}
                            page={<PaymentHistoryPage />}
                        />}
                    />

                    <Route
                        path='transactions/refund-payment-history'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.refundPaymentHistory}
                            page={<RefundPaymentHistoryPage />}
                        />}
                    />

                    <Route
                        path='transactions/settlements'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.settlements}
                            page={<SettlementsPage />}
                        />}
                    />

                    <Route
                        path='transactions/draft-transactions'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.draftTransactions}
                            page={<DraftTransactionsPage />}
                        />}
                    />

                    <Route
                        path='transactions/refund-payment/:transactionReferenceId'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.refundPayment}
                            page={<RefundPaymentPage />}
                        />}
                    />

                    <Route
                        path='order-due'
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.orderDue}
                            page={<OrderDuePage />}
                        />}
                    />

                    <Route
                        path="create-update-architect-details"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.directory}
                            page={<CreateUpdateArchitectDetailsPage />}
                        />}
                    />

                    <Route
                        path="directory-master"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.directory}
                            page={<DirectoryMasterPage />}
                        />}
                    />


                    <Route
                        path="directory-master/create-update-directory-user"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.directory}
                            page={<CreateUpdateUserDirectoryPage />}
                        />}
                    />

                    <Route
                        path="directory-master/create-update-directory-comapny"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.directory}
                            page={<CreateUpdateCompanyDirectoryPage />}
                        />}
                    />

                    <Route
                        path="architect-details"
                        element={<AuthAppLayout
                            featuredPage={allFeaturedModules.directory}
                            page={<ArchitectDetailsPage />}
                        />}
                    />
                </Routes>
            </BrowserRouter>
        </>


    )
}

export default AppRoutes;