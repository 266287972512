import React, { useEffect } from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { APIURL } from './service/api-service';
import { APIResponseEntity } from './interface/api-responce';
import { IsStringNullEmptyOrUndefined } from './utils/null-check';

function App() {

  useEffect(() => {
    const checkVersion = async () => {
      try {
        if (APIURL && process.env.NODE_ENV !== "development") {
          const response = await fetch(APIURL);
          const resp: APIResponseEntity = await response.json();

          if (resp && resp.status && resp.statusCode === 200) {
            console.log("VERSION: ", resp.data && resp.data.version);

            const version: string = resp.data && resp.data.version ? resp.data.version : "";

            if (!IsStringNullEmptyOrUndefined(version) && version !== localStorage.getItem('appVersion')) {
              localStorage.setItem('appVersion', version);
              window.location.reload();
            }
          }
        }
      } catch (error) {
        console.error('Failed to fetch version:', error);
      }
    };

    const interval = setInterval(checkVersion, 20000); // Check every 20 seconds

    checkVersion(); // Initial check

    return () => clearInterval(interval);
  }, []);

  return (
    <ToastContainer />
  );
}

export default App;
