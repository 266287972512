import React from 'react'

function footer() {
    return (
        <>
            <footer className="footer">
                <div className="d-sm-flex justify-content-center">
                    <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Copyright © {new Date().getFullYear()}. All rights reserved.</span>
                </div>
            </footer>
        </>
    )
}

export default footer