/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { APIDownloadCustomerFeedbackFormPDF, APIDownloadGoodsDeliveryNotePDF, APIDownloadMeasurementSheetPDF, APIDownloadWorkCompletionNotePDF } from '../../service/api-service';
import { toast } from 'react-toastify';
import { toastOptObj } from '../../utils/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { ProcoreProjectPDFLinksEnumEntity } from '../../utils/enum';
import { IsStringNullEmptyOrUndefined } from '../../utils/null-check';
import Loader from '../../components/loader';
import { AxiosResponse } from 'axios';
import { APIResponseEntity } from '../../interface/api-responce';
import { DownloadPDFEntity } from '../../interface/download-pdf';

function CustomerFeedbackFormPDF() {

    const [pdfURL, setPdfUrl] = useState("");

    const navigate = useNavigate();

    const paramProCoreId = useRef<string | undefined>(useParams().proCoreId);
    const paramPDFCategory = useRef<string | undefined>(useParams().PDFCategory);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchDefaultData = async (): Promise<void> => {
            if (paramProCoreId.current && !IsStringNullEmptyOrUndefined(paramProCoreId.current)) {
                await fetchPDF();
            }
        }
        fetchDefaultData();
    }, [paramProCoreId.current]);

    const fetchPDF = async () => {
        if (
            paramPDFCategory.current && !IsStringNullEmptyOrUndefined(paramPDFCategory.current) &&
            paramProCoreId.current && !IsStringNullEmptyOrUndefined(paramProCoreId.current)
        ) {
            const selectedCategory = paramPDFCategory.current;

            setIsLoading(true);
            const resp: AxiosResponse | null = selectedCategory === ProcoreProjectPDFLinksEnumEntity.MEASUREMENT_SHEET_PDF
                ? await APIDownloadMeasurementSheetPDF(paramProCoreId.current, "project-link")
                : selectedCategory === ProcoreProjectPDFLinksEnumEntity.GOODS_DELIVERY_NOTES_PDF
                    ? await APIDownloadGoodsDeliveryNotePDF(paramProCoreId.current, "project-link")
                    : selectedCategory === ProcoreProjectPDFLinksEnumEntity.WORK_COMPLETION_SHEET_PDF
                        ? await APIDownloadWorkCompletionNotePDF(paramProCoreId.current, "project-link")
                        : selectedCategory === ProcoreProjectPDFLinksEnumEntity.CUSTOMER_FEEDBACK_PDF
                            ? await APIDownloadCustomerFeedbackFormPDF(paramProCoreId.current, "project-link")
                            : null;


            if (resp && resp.data && resp.status === 200) {
                const axiosResp: APIResponseEntity = resp.data;

                if (axiosResp && axiosResp.data && axiosResp.status && axiosResp.statusCode === 200 && axiosResp.data.data) {
                    const downloadObj: DownloadPDFEntity = axiosResp.data.data;
                    setPdfUrl(downloadObj.AWSFilePath);
                    setIsLoading(false);
                } else {
                    console.log("ELSE - 1");
                    toast.error(axiosResp.message, { ...toastOptObj });
                    setTimeout(() => { navigate('/'); }, 2500);
                }
            } else {
                console.log("ELSE - 2");
                toast.error("axiosResp.message", { ...toastOptObj });
                setTimeout(() => { navigate('/'); }, 2500);
            }
        }
    };

    const getCategoryName = (): string => {
        let str = "";
        if (paramPDFCategory.current) {
            switch (paramPDFCategory.current) {
                case ProcoreProjectPDFLinksEnumEntity.GOODS_DELIVERY_NOTES_PDF:
                    str = "Goods Delivery Notes";
                    break;

                case ProcoreProjectPDFLinksEnumEntity.WORK_COMPLETION_SHEET_PDF:
                    str = "Work Completion Sheet";
                    break;

                case ProcoreProjectPDFLinksEnumEntity.CUSTOMER_FEEDBACK_PDF:
                    str = "Customer Feedback";
                    break;

                case ProcoreProjectPDFLinksEnumEntity.MEASUREMENT_SHEET_PDF:
                    str = "Measurement Sheet";
                    break;

                default:
                    break;
            }
        }

        return str;
    };

    const download = (): void => {
        const link: HTMLAnchorElement = document.createElement('a');

        if (link && pdfURL) {
            link.href = pdfURL;
            link.target = '_blank';
            link.download = pdfURL.replace(/^.*[\\/]/, '').split("?")[0];
            link.dispatchEvent(new MouseEvent('click'));
        }
    }

    return (
        <>
            <Loader isLoading={isLoading} />

            {
                !isLoading &&
                <section>
                    <div className="container my-5">
                        <div className="row mb-3">
                            <div className="brand-logo">
                                <img src="assets/images/logo.png" alt="logo" />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-12">
                                <div className="d-flex align-items-center">
                                    <h3 className='fw-bold'> {getCategoryName()}: </h3>
                                    <button type="button" className="btn btn-block btn-success ms-3" onClick={download}>
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <object data={pdfURL} type="application/pdf" width="100%" height="1000">
                                <iframe title={Date.now().toString()} src={pdfURL} width="100%" height="1000">
                                    <p>This browser does not support PDF!</p>
                                </iframe>
                            </object>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}

export default CustomerFeedbackFormPDF;